/**
 * Direttiva che costruisce l'input per la barra di ricerca
 */
import { Item, User, SenecaResponse, UserGroup } from "atfcore-commonclasses";

export interface IsearchBarInputDirectiveScope extends ng.IScope {
	isBackBtnDisabled: boolean;
	isRemoveAllFiltersBtnDisabled: boolean;
	searchBarManager: any;
	loadCourseTitles: Function;
	searchRemoteTitlesPromise: any;
	saveTextParam: Function;
	saveNewSearch: Function;
	filterParams: any;
}
angular.module('app').directive("searchBarInput", ($translate, SearchBarManager, $q, toaster, ItemDetailService, $rootScope, $state) => {
	return {
		restrict: 'E',
		transclude: true,
		scope: {
			saveNewSearch: '=',
			historyBack: '=',
			hasTabsAtLeastOneFilter: '=',
			removeFilters: '=',
			inputPlaceholder: '=',
			isFirstTabSelected: '=',
			filterParams: '=',
			isBackBtnDisabled: '='
		},
		link: link,
		templateUrl: 'app/shared/searchBar/searchBarInput/searchBarInput.html'
	};
	function link($scope: IsearchBarInputDirectiveScope, element: JQuery, attrs: ng.IAttributes) {
		$scope.searchBarManager = SearchBarManager;

		// Se non ho nessun filtro, disabilito il pulsante per rimuovere tutti i filtri (tranne le categorie)
		if (!SearchBarManager.countSelectedFilters(true)) {
			$scope.isRemoveAllFiltersBtnDisabled = true;
		}

		// Inizia una nuova ricerca, salvando il titolo del corso selezionato
		$scope.saveTextParam = (courseTitle: any): void => {
			if (courseTitle && courseTitle.length) {
				$scope.filterParams.searchedText = courseTitle;
				// Avvio una nuova ricerca
			//	if($state.includes('app.gtp.coursesAndEvents')){

			//	}
				$scope.saveNewSearch(null, true);
			}
		};

		// Recupera i titoli dei corsi in base alla ricerca effettuata
		$scope.loadCourseTitles = (searchedText: string) => {
			return $q((resolve: Function, reject: Function) => {
				// Eseguo la ricerca, eliminando le eventuali altre pendenti
				if ($scope.searchRemoteTitlesPromise) {
					$scope.searchRemoteTitlesPromise.$cancelRequest();
				}
				$scope.searchRemoteTitlesPromise =
					ItemDetailService.searchFieldSuggesterForItem.query({
						searchedText: searchedText,
						numRecords: 10
					});
				$scope.searchRemoteTitlesPromise.$promise
					.then((data: SenecaResponse<any>) => {
						if (data.error) {
							// Dati non validi, quindi alzo l'errore
							toaster.pop("error", $translate.instant('error.generic.TITLE'), $translate.instant('error.generic.MESSAGE'));
							reject();
						} else {
							// Torno i risultati della ricerca
							resolve(data.response);
						}
						// Annullo la promessa
						$scope.searchRemoteTitlesPromise = null;
					})
					.catch((error: any) => {
						// Annullo la promessa
						$scope.searchRemoteTitlesPromise = null;
						// Non mostro la modale di errore se ho cancellato volutamente la richiesta           
						if (!error || (error.config.timeout && error.config.timeout.$$state && error.config.timeout.$$state.status && error.config.timeout.$$state.status !== 1)) {
							let errors: Array<any> = [];
							errors.push({ severity: "danger", message: $translate.instant("error.generic.UNKNOWN_ERROR") });
							$rootScope.$emit("showApplicationModalErrors", errors);
						}
						reject();
					});
			});
		};
	}
});